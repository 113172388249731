.App {
  text-align: center;
  margin: 0 auto;
  /* width: 100%; */
  font-family: Poppins, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.App-header {
  max-width: 781px;
  width: auto;
  position: fixed;
  top: 24px;
  left: 0;
  right: 0;
  height: 72px;
  border-radius: 20px;
  background-color: #ffffff;
  margin: 0 auto;
  box-shadow: 0px 4px 10px 0px #0000000d;
  z-index: 100;
  padding: 0 12px;
}

.header-wrapper {
  height: 100%;
  justify-content: space-between;
  flex: 1;
}

.logo {
  width: 32px;
  height: auto;
  object-fit: contain;
}

.line {
  height: 24px;
  width: 1px;
  background-color: #d0d0d0;
  margin: 0 12px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.nav-item {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  cursor: pointer;
}

.nav-item-la {
  width: 20px;
}

.App {
  text-align: center;
  margin: 0 auto;
  /* width: 100%; */
  font-family: Poppins, "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.App-header {
  max-width: 781px;
  width: auto;
  position: fixed;
  top: 24px;
  left: 0;
  right: 0;
  height: 72px;
  border-radius: 20px;
  background-color: #ffffff;
  margin: 0 auto;
  box-shadow: 0px 4px 10px 0px #0000000d;
  z-index: 100;
  padding: 0 12px;
}

.header-wrapper {
  height: 100%;
  justify-content: space-between;
  flex: 1;
}

.logo {
  width: 32px;
  height: auto;
  object-fit: contain;
}

.line {
  height: 24px;
  width: 1px;
  background-color: #d0d0d0;
  margin: 0 12px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.nav-item {
  font-size: 14px;
  font-weight: 400;
  color: #6f6f6f;
  cursor: pointer;
}

.content {
  display: block;
  position: relative;
  width: 100%;
  height: 1000px;
  padding: 190px 0 0;
  margin: 0 auto;
}

.wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 600px;
  padding-bottom: 400px;
  /* max-width: 1080px; */
  overflow: hidden;
  margin: 0 auto;
  background-color: #f5f5f5;
}

.swiper-wrapper {
  width: 100%;
  height: 1000px;
  padding-bottom: 400px;
}

.round1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -40px;
  max-width: 680px;
  height: auto;
  object-fit: contain;
  z-index: 3;
  animation: round-rotate 20s linear infinite;
}

.round2 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -200px;
  max-width: 1000px;
  height: auto;
  object-fit: contain;
  z-index: 3;
  animation: round-rotate 25s linear infinite;
}

.round-desc {
  width: auto;
  height: 169px;
  max-width: 883px;
  object-fit: contain;
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
  top: 213px;
  margin: 0 auto;
}

.app-1-logo {
  width: auto;
  max-width: 400px;
  height: 173px;
  object-fit: contain;
}

.app-1-desc {
  width: 100%;
  max-width: 856px;
  height: auto;
  object-fit: contain;
  /* margin-top: 32px; */
}

.process-line {
  background-color: #9c9c99;
  width: 280px;
  height: 2px;
  position: relative;
  margin: 32px auto;
}

.line-dot-blue {
  width: 24px;
  height: 24px;
  right: 0;
  top: -11px;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(79, 114, 238, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-dot-blue-centra {
  background-color: #4f72ee;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
}

.line-dot-gray {
  background-color: #9c9c99;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
}

.app-1-about {
  width: 1000px;
  max-width: 80%;
  height: auto;
}

.app-1-env-img {
  width: auto;
  max-width: 80%;
  height: 68.5px;
}

.app-1-env-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 96px;
  animation: scroller 30s linear infinite;
}

.app-1-env-item {
  width: 350px;
  height: 271px;
}

.app-1-history {
  width: 980px;
  max-width: 100%;
  height: auto;
}

.app-1-honors {
  width: 1056px;
  max-width: 100%;
  height: auto;
  /* background-color: #f5e9ba; */
}

@keyframes round-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scroller {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 1080px) {
  .wrapper {
    width: 100%;
    height: 220px;
  }
  .round1 {
    width: 280px;
    top: -40px;
  }
  .round2 {
    width: 380px;
    top: -80px;
  }

  .round-desc {
    width: 80%;
    top: 10%;
  }
  @keyframes scroller {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-300%);
    }
    100% {
      transform: translateX(300%);
    }
  }
}
